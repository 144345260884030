<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th class="border-top-0">
							{{ translate('state') }}
						</th>
						<th class="border-top-0">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('total_orders') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('shipping') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('duties') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total_sales') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('refunds') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('net_sales') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ item.attributes.region_name }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/header/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.num_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.base_shipping }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.base_tax }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.base_total }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.base_refund }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.base_net_sales }}
						</td>
					</tr>
					<tr class="text-right">
						<td colspan="2">
							<b />
						</td>
						<td class="text-center">
							<b>{{ totals.num_orders }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_shipping }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_tax }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_total }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_refund }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.base_net_sales }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import { PICKUP_STORE } from '@/settings/Country';
import {
	MDY_FORMAT,
} from '@/settings/Dates';
import {
	Report, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';

export default {
	name: 'SalesStates',
	messages: [Report, Grids, Countries],
	mixins: [FiltersParams],
	data() {
		return {
			sales: new Sales(),
		};
	},
	computed: {
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';

		if ([this.filters.country, this.$route.query.country].includes(PICKUP_STORE)) {
			delete this.filters.country;
			delete this.$route.query.country;
		}

		this.sales.getSalesByState().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
		});
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			this.sales.getSalesByState(options);
		},
	},
};
</script>
